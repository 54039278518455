* {
  margin: 0px;
  padding: 0px;
}

html,body
{
    width: 100%;
    height: 100%;
    overflow-x: hidden; 
}

.header {
  width: 100%;
  height: 150px;
  align-self: center;
}

.logo-bar {
  height: 100%;
  max-width: 100%;
  display: flex;
  /* object-fit: cover; */
  align-items: center;
}

.webname-full{
  max-height: 100px;
  max-width: 500px;
  align-self: center;
}

.logo {
  max-height: 160px;
  max-width: 500px;
  align-self: center;
}

.webname-title, .webname-subtitle {
  max-height: 75%;
  align-self: center;
}

.website-name {
  max-height: 80%;
  width: 75%;
}

.table {
  width: 75%;
  margin: 0 auto;
}

.table img {
  max-width: 50px;
  max-height: 50px;
  margin: auto;
}


.webname-title, .webname-subtitle {
  display:none;
}


@media screen and (min-width: 993px) {
  .banana-wrap {
    /* width: 100%; */
    background: url('./assets/banomnom-website-banner.png') center/contain;
  }
}

/* On screens that are 992px wide or less, the background color is blue */
@media screen and (max-width: 992px) {
  .banana-wrap {
    background: url('./assets/banana-pad.png') center/contain;
  }
}

/* On screens that are 600px wide or less, the background color is olive */
@media screen and (max-width: 600px) {
  .banana-wrap {
    background: url('./assets/banana-phone.png') center/contain;
  }

  .header {
    height: 125px;
  }

  .logo {
    max-height: 120px;
  }

  .webname-full {
    display: none;
  }
  .webname-title, .webname-subtitle {
    display:block;
    align-self: flex-start;
    padding: 1%;
  }
  .webname-title {
    max-height: 25px;
  }
  .webname-subtitle {
    max-height: 20px;
  }
  .sm-device {
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* width: 500px; */
  }

}

.name-form {
  padding: 2%;
}
.form-control {
  max-width: 200px;
  margin: 0 auto;
}

.custom-button {
  margin-top: 2%;
  background-color: #ffe213;
  border: 1px solid #f8f9fa;
  /* Border color and width */
  color: #000000;
}


/* Clear the floats to prevent layout issues */
.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

.container {
  padding: 10px;
}

.text-center img {
  max-height: 50px;
}

.bananas .unsent, .feedbackSent banana  {
  display: None;
}

.sent {
  color: grey;
  font-style: italic;
}

.hide-banana {
  display: None;
}
.show-bananas {
  display: inline-block;
}

.table-wrapper {
  text-align: center;
}

